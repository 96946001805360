@use "@/scss/config" as *;

.greeting {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin: 0;

  .greeting_content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    justify-items: center;
    gap: 0;
    width: 100%;
    max-width: 1500px;
    padding: 0 var(--space-xs) var(--space-md);

    @include min-width(md) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      gap: var(--space-md);
      padding: 0 var(--space-md) var(--space-md);
    }
    @include min-width(lg) {
      grid-template-columns: 1fr 600px 1fr;
      grid-template-rows: auto;
      gap: var(--space-lg);
    }

    .greeting_main {
      grid-column: 1;
      grid-row: 1;
      @include min-width(md) {
        grid-column: 1 / -1;
        grid-row: 1;
      }
      @include min-width(lg) {
        grid-column: 2;
        grid-row: 1;
      }
    }
    .greeting_left {
      grid-column: 1;
      grid-row: 2;
      max-width: 400px;
      width: 100%;
      @include min-width(md) {
        grid-column: 1;
        grid-row: 2;
      }
      @include min-width(lg) {
        grid-column: 1;
        grid-row: 1;
        max-width: 320px;
      }
    }
    .greeting_right {
      grid-column: 1;
      grid-row: 3;
      max-width: 400px;
      width: 100%;
      @include min-width(md) {
        grid-column: 2;
        grid-row: 2;
      }
      @include min-width(lg) {
        grid-column: 3;
        grid-row: 1;
        max-width: 320px;
      }
    }

    .greeting_main {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 600px;
      width: 100%;

      .greeting_stats {
        display: flex;
        flex-direction: row;
        gap: var(--space-xs);
        width: 100%;
        margin-bottom: var(--space-md);

        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: var(--space-xs);
        }

        p, .count_link {
          font-size: var(--text-sm);
          font-weight: bold;
          margin: 0;
          text-align: center;
        }
        .count_link {
          color: var(--color-contrast-high);
          text-decoration: none;
        }
        .count_link:hover {
          color: var(--color-contrast-high);
          text-decoration: underline;
        }
      }
    }
  }

  h1.title {
    width: 100%;
    margin-top: var(--space-xs);
    margin-bottom: var(--space-sm);
    font-weight: bold;
    line-height: 1.3;
    letter-spacing: 0.01em;
    text-align: center;
    vertical-align: middle;

    font-size: var(--text-2xl);
    @include min-width(sm) {
      font-size: var(--text-3xl);
    }

    .highlighter {
      color: rgb(255, 162, 56);
      white-space: nowrap;
      background-size: 100%;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
      background-image: linear-gradient(
                      180deg,
                      hsl(36, 90%, 75%) 0%,
                      hsl(36, 90%, 67%) 30%,
                      hsl(32, 90%, 57%) 80%,
                      hsl(32, 90%, 46%) 90%,
                      hsl(32, 90%, 45%) 100%
      );
      text-shadow: none;
    }
  }
  .subtitle {
    text-align: center;
    margin: var(--space-xs) 0;
    font-size: var(--text-base);

    .icon {
      display: inline-block;
      vertical-align: middle;
      width: 2em;
      height: auto;
      max-height: 2em;
      max-width: 2em;
    }
  }

  .logo_banner {
    font-size: var(--text-2xl);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--space-lg);
  }

  .greeting_button {
    display: inline-block;
    position: relative;
    text-decoration: none;

    @include max-width(sm) {
      svg {
        display: none;
      }
    }

    svg {
      position: absolute;
      height: 100%;
      top: 0;
      fill: var(--color-contrast-higher);
      pointer-events: none;

      &.left_arrow {
        right: 110%;
        transition: right 0.15s ease-in-out;
      }

      &.right_arrow {
        left: 110%;
        transition: left 0.15s ease-in-out;
      }
    }

    &:hover svg {
      &.left_arrow {
        right: 105%;
      }

      &.right_arrow {
        left: 105%;
      }
    }
  }
}

section.learn {
  text-align: center;
  padding-top: 0;

  .content {
    text-align: left;
    max-width: 700px;
    line-height: var(--body-line-height);
    padding: 0;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    font-size: var(--text-2xl);
  }
}


.banner {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: var(--space-md) var(--space-sm);
  border: 5px solid black;
  border-left: none;
  border-right: none;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 1);
}

.collision_image {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-height: 10em;
}

.banner_ad {
  display: flex;
  flex-direction: column;
  font-size: var(--text-md);
  color: var(--color-contrast-high);
  pointer-events: auto;

  span {
    display: block;
    padding-bottom: 0.2em;
    flex-grow: 0;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 6em;
  }
}

.view_all_leaderboards_link {
  width: 100%;
  padding: var(--space-2xs);
  border: 1px solid transparent;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: var(--space-xs);
  overflow: hidden;
  margin-top: var(--space-2xs);
}
